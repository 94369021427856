body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1d1e21;
  font-size: 1rem;
  color:#fff;
}

a, a:visited, a:link{
  color:#fff;
  text-decoration:none;
}

a:hover{
  text-decoration:  underline;
  color: #fefefe;
}

img{
  border:none;
}

p{
  margin-top:0px;
  margin-bottom:5px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
