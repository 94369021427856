

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

  .exchvnge-header {
    background-color: #000;
    
    margin-bottom:10px;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align:center;
    z-index:100;
    position:fixed;
    width:100%;
    top:0;
  
    
  }

 

  .exchvnge-header .app-logo{
    max-width: 200px;
    margin: 15px auto 5px auto;
    display:inline-block;
  }

  .app-logo img{
    width: 100%;
  }

  .exchvnge-footer { 
    text-align:center;
    position:fixed;
    bottom:0;
    width:100%;
    z-index:100;
  }

  .exchvnge-footer .copyright{
    font-size:0.8rem;
    font-weight:bold;
    margin-bottom:10px;
  }

  .event-item{
    width: 80%;
    height: 30vh;
    min-width: 250px;
    min-height: 250px;
    max-width: 800px;
  
    margin: 0 auto;
  }

  .event-item .event-description {
    padding: 10px 15px;
    background-color:#fff;
    color: #333;
 }
 
 .event-item .event-description h2{ 
   font-size: 1.2rem;
   font-weight: bold;
 }
 
 .event-item .event-description p {
   font-size: 0.9rem;
   font-weight: normal;
 }
 
 .event-item .event-image {
   display:block;
   position:relative;
 }
 
 .event-item .event-image img{
   display:block;
   width: 100%;
 }
 
 .event-item .event-image .event-host{
   position: absolute;
   bottom: 10px;
   right: 10px;
   z-index:10;
   background-color: #000;
   padding:8px 15px;
   border-radius: 50px;
 }
 
 .event-item .event-image .event-host .host-avatar {
   display:inline-block;
   margin-right: 8px;
  line-height: 30px;
 }
 
 .event-item .event-image .event-host h3 {
   display:inline-block;
   line-height: 30px;
   font-size:0.9rem;
   vertical-align: top;
 }
 
 #live-view h1{
  margin:0 auto;
  font-size: 1.2rem;
  margin: 80px auto;
  text-align:center;
  position:absolute;
  width:100%;
  top:0px;
  left:0px;
  z-index:50;
  text-shadow: 1px 1px #000;
}

#chat-log-container{
  position:absolute;
  bottom:85px;
  z-index:20;
  margin: 0 auto;
  left: 0; 
  right: 0;
}

#chat-log{
  height: 30vh;
  overflow-y: scroll;
  width: 100%;
}

#chat-log .chat{
margin-bottom:3px;
padding: 5px 15px;
text-shadow: 1px 1px #666;
}

#chat-log .pinned-chat{
background-color:#dedede;
border-radius: 30px;
padding: 5px 15px;
margin-bottom:3px;
text-shadow: 1px 1px #666;
}

#chat-log .chat-avatar{
width: 25px !important;
min-width: 25px !important;
}

#chat-log .sender{
margin-right: 10px;
font-weight: bold;
font-size: 0.8em;
}

#chat-log .message{
font-size: 0.8em;
font-weight: normal;
}

.pinned-chat .sender, .pinned-chat .message{
color:#333;
}

.remote-videos-container {
  display:block;
  min-height: 100vh;
  width:100%;
  display: flex;
  flex: 1;
  position:relative;
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
  margin:0 auto !important;
}

.remote-videos-container .remote-video {
  position:relative;
  height: 100vh;
  width: 100%;
}


.remote-videos-container.host-count-2 .remote-video, .remote-videos-container.host-count-3 .remote-video, .remote-videos-container.host-count-4 .remote-video{
  height: 50vh;
  margin-bottom:0;
  display:inline-block;
}

.remote-video.half-width{
  width:50%;
}


@media (max-width: 880px) {
  #live-view, #stream-container{
    padding-left: 0;
    padding-right:0;

  }
}